@media only screen and (max-width: 768px) {
    .testimonial p {
      font-size: 0.8rem;
      line-height: 1.5rem;
    }
  
    .testimonial h6 {
      font-size: 1rem;
    }
  }
  
  @media only screen and (max-width: 576px) {
    .testimonial img {
      width: 80px !important;
      height: 60px !important;
      object-fit: cover;
    }
  }
  .testimonial img {
    width: 150px !important;
    height: auto !important;
    object-fit: cover;
  }

  .intro-text {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .intro-heading {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .intro-paragraph {
    font-size: 18px;
  }


  .parceiros-heading {
    text-align: center;
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .parceiros-card {
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    height: 200px; /* Altura dos cards */
    margin-bottom: 40px; 
  }
  
  .parceiros-card:hover {
    transform: translateY(-5px);
  }
  
  .parceiros-img-container {
    height: 150px; /* Altura das imagens */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .parceiros-img {
    max-width: 60%;
    max-height: 60%;
  }
  
  .parceiros-title {
    text-align: center;
    font-size: 16px; /* Tamanho do título */
    font-weight: bold;
    margin-top: 10px;
  }
  