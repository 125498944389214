/* Estilo para o componente ReservationReturnPolicy */

/* Estilo geral */
.reservation-return-policy {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  /* Estilo para os títulos */
  .reservation-return-policy h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .reservation-return-policy h3 {
    font-size: 20px;
    margin-bottom: 8px;
  }
  
  /* Estilo para os pontos destacados */
  .reservation-return-policy strong {
    color: #333;
  }
  
  /* Estilo para o link */
  .reservation-return-policy a {
    color: #001F9F;
    text-decoration:none;
  }
  
  /* Estilo para os parágrafos */
  .reservation-return-policy p {
    margin-bottom: 12px;
    color: #555;
  }

  
  /* Estilo para os textos da seção de devolução */
  .reservation-return-policy .devolution-text {
    margin-top: 30px;
  }
  