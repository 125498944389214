.filter-options {
    display: flex;
    flex-direction: column;
  }
  
  .filter-options label {
    margin-bottom: 5px;
    display: block;
  }

