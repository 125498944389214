.payment label {
  font-weight: 600;
  color: #001F3F;
}

.payment button {
  padding: 8px 15px;
  border-radius: 5px;
  background: #001F3F;
  color: #fff;
  border: none;
  outline: none;
}

.payment img {
  height: 50px;
  padding: 0;
  vertical-align: middle;
}
