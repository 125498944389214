/* cookie-policy.css */

.cookie-policy {
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .cookie-policy p {
    margin-bottom: 10px;
    line-height: 1.5;
    color: #333;
  }
  
  .cookie-policy ul {
    margin-bottom: 20px;
    list-style-type: disc;
    padding-left: 20px;
    color: #333;
  }
  
  .cookie-policy li {
    margin-bottom: 5px;
  }
  
  .cookie-policy strong {
    font-weight: bold;
  }
  
  .cookie-policy a {
    color: #007bff;
    text-decoration: none;
  }
  
  .cookie-policy a:hover {
    text-decoration: underline;
  }
  