/* Estilos para o componente CancellationRefundPolicy */
.liability-damage-policy {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
  }
  
  .liability-damage-policy h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .liability-damage-policy h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 24px 0 12px;
  }
  
  .liability-damage-policy p {
    font-size: 16px;
    margin-bottom: 12px;
    color: #555;
  }
  