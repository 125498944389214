.reservation-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .reservation-item {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .reservation-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .reservation-summary {
    padding: 16px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .reservation-summary h3 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .reservation-summary p {
    margin: 0;
  }
  
  .reservation-details {
    margin-top: 10px;
    background-color: #f5f5f5;
    padding: 16px;

  }
 
  .reservation-details p {
    margin: 8px 0;
  }

  
  
  .details-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .btn-alter {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    padding: 0.75rem 1rem;
    color: white !important;
    background-color: #001F3F !important;
    margin-right: 5px;
    border: 0 !important;

  }

  .btn-cancel {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    padding: 0.75rem 1rem;
    color: white !important;
    background-color: #dc3545 !important;
    border: 0 !important;
  }


  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .pagination li {
    display: inline-block;
    margin: 0 5px;
  }
  
  .pagination a,
  .pagination .active a {
    display: block;
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #f8f8f8;
    color: #333;
    text-decoration: none;
    cursor: pointer;
  }
  
  .pagination .active a {
    font-weight: bold;
    background-color: #ccc;
  }
  
  .pagination a:hover {
    background-color: #eaeaea;
  }
  
