.scroll-to-top-arrow {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #333;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .scroll-to-top-arrow.visible {
    opacity: 1;
  }
  
  .scroll-to-top-arrow__icon {
    color: #fff;
    font-size: 24px;
  }
  