
.header{
  border-bottom: 1px solid #ddd;
}
.header__top {
  padding: 10px 0px;
  background: #001F3F;
  color: #fff;
}

.header__top__left {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.header__top__left span {
  font-size: 0.9rem;
}

.header__top__help {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
}

.header__top__help i {
  background: #fff;
  color: #001F3F;
  padding: 2px 4px;
  border-radius: 50px;
  font-size: 0.7rem;
}

.header__top__right a {
  text-decoration: none;
  color: #fff;
  font-size: 0.9rem;
}

.header__top__right a:hover {
  color: #fff;
}

/* ============ header middle style =========== */

.header__middle {
  padding: 20px 0px;
}

.logo h1 a span {
  font-size: 1.5rem;
}

.logo h1 a i {
  font-size: 2.2rem;
}

.logo h1 a {
  text-decoration: none;
  color: #001F3F;
  font-weight: 700;
}

.logo h1 {
  line-height: 1.5rem;
}

.header__location span i {
  font-size: 2.2rem;
  color: #001F3F;
}

.header__location-content h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #001F3F;
}

.header__location-content h6 {
  font-weight: 600;
}

.header__btn {
  background: #001F3F !important;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
}

/*
.header__btn a {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  text-decoration: none;
  color: #fff;
  justify-content: end;
  font-size: 0.9rem;
}
*/

.header__btn a:hover {
  color: #fff;
}

/* ================ main navbar styles =========== */

.main__navbar {
  padding: 10px 0px;
  background: #fff;
}

.mobile__menu i {
  color: #001F3F;
  font-size: 1.3rem;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
  line-height: 20px;
}

.nav__item {
  color: #001F3F;
  text-decoration: none;
  transition: 0.3s;
}

.nav__item:hover {
  color: #FFC300;
}

.search__box {
  border: 1px solid #7c8a972f;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  background: #020a4de9;
}

.search__box input {
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}

.search__box input::placeholder {
  font-size: 0.8rem;
}
.search__box span i {
  color: rgba(255, 255, 255, 0.555);
  cursor: pointer;
}

.nav__active {
  color: #FFC300;
}

@media only screen and (max-width: 991px) {
  .header__top,
  .header__middle,
  .nav__right {
    display: none;
  }

  .mobile__menu {
    display: block;
  }

  .navigation {
    background: rgb(0, 13, 107, 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
  }

  .menu {
    width: 250px;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
  }

  .menu a {
    color: #001F3F;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .menu__active {
    display: block;
  }
}


/* header.css */

.button {
  display: inline-block;
  padding: 3px 11px;
  border-radius: 3px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button--login {
  background-color: #001F3F;
  color: #fff;
  border: none;
}

.button--login:hover {
  background-color: #001F3F;
  color: #fff;
}

.button--register {
  background-color: #FFC300;
  color: #001F3F;
  border: none;
}

.button--register:hover {
  background-color: #FFD700;
  color: #001F3F;
}

.dropdown-toggle {
  padding: 2px 11px !important;
  font-size: 0.9rem !important;
  background-color: #fff !important;
  border-color: #fff !important;
}



/* Seletor para o menu suspenso */


.dropdown-menu {
  padding: 0 !important;
  font-size: 0.9rem !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  background-color: #fff !important; /* Altere esta cor de fundo se necessário */
}

.dropdown-item {
  padding: 0.5rem 1rem;
  color: #001F3F !important; /* Altere a cor do texto para preto */
  transition: background-color 0.3s !important;
  padding: 8px 18px !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #FFC300 !important;
  color: #000 !important; /* Altere a cor do texto quando estiver em foco */
}


.dropdown-menu.show {
  max-height: 200px !important;
  overflow-y: auto  !important;
  margin-top: 10px !important;
}

.avatar-container {
  display: flex;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  width: 32px;
  height: 36px;
  margin-right: 8px;
  cursor: pointer;
}

.name {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.flag-icon {
  width: 25px; /* Ajuste o tamanho da largura conforme necessário */
  height: auto; /* Ajuste a altura automaticamente */
}


.language-label {
  font-size: 16px;
  font-weight: bold;
  margin-right: 8px;
  color: #001F3F;
}

