
  .btn-login {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    padding: 0.75rem 1rem;
    color: white !important;
    background-color: #001F3F !important;
  }
  
  .btn-google {
    color: white !important;
    background-color: #ea4335 !important;
  }
  
  .btn-facebook {
    color: white !important;
    background-color: #3b5998 !important;
  }
  .forgot-password-link, 
  .create-account-link{
    text-decoration: none;
  }

  .terms-of-service {
    display: block;
    margin-top: 10px;
    font-size: 0.9rem;
    text-align: center;
    color: #777;
  }

  .error-text {
    color: red;
  }
  
  .form-floating>.form-control,
  .form-floating>.form-control-plaintext,
  .form-floating>.form-select {
    height: calc(3rem + 2px) !important;
    line-height: 1 !important;
  }
  
  .form-floating>label {
    padding: 0.5rem 0.75rem !important;
  }

  .invalid-feedback {
    color: red;
    display: block !important;
  }
  