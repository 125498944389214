/* privacy-policy.css */

.privacy-policy {
    margin-bottom: 20px;
  }
  
  .privacy-policy h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .privacy-policy h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .privacy-policy p {
    margin-bottom: 15px;
    line-height: 1.5;
    color: #555;
  }
  
  .privacy-policy a {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  