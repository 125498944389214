.personaldata__form {
    width: 47.5%;
  }
  
  .form-personal-data .personaldata__form
   input {
    padding: 8px 15px;
    border: 1px solid #7c8a972a;
    color: #000;
    width: 100%;
    height: 50px !important;
  }

  .react-international-phone-input {
    border: 1px solid #7c8a972a !important;
    color: #000 !important;
    width: 100% !important;
    height: 50px !important;
    border-radius:0px !important;
    margin-top: 0.4rem !important;
    font-size: 1rem !important;

  }
  .react-international-phone-country-selector-button{
    height: 50px !important;
    margin-top: 0.4rem !important;
    border-radius:0px !important;
  }

  .form-personal-data .form-control {
    width: 100% !important;
    border-radius:0px !important;
    width: 47.5% !important;
  }
  
  .form-personal-data .personaldata__form select,
  .form-personal-data .textarea {
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    color: #000;
    border: 1px solid #7c8a972a;
    border-radius: 0px;
  }
  
  .time__picker::before {
    content: "Journey Time";
    color: #000;
    margin-right: 0.5rem;
  }
  
  .form-personal-data .textarea:focus,
  .personaldata__form input:focus,
  .personaldata__form select:focus {
    outline: none;
  }
  
  @media only screen and (max-width: 768px) {
    .personaldata__form,
    .form-personal-data .form-control {
      width: 100% !important;
    }
  
    .form-personal-data .personaldata__form input,
    .form-personal-data .textarea,
    .personaldata__form select {
      font-size: 0.8rem;
    }
  }

  .btn-update {
    background: #001F3F !important;
    color: #fff !important;
    padding: 5px 10px !important;
    border-radius: 4px  ;
    border: none;
    cursor: pointer;
    font-size: 1rem ;
    font-weight: 500 !important;
    margin-top: 1rem;
  }

  .form-floating>.form-control-plaintext:focus, .form-floating>.form-control-plaintext:not(:placeholder-shown), .form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.40rem !important;
    padding-bottom: 0.625rem !important;
}
  