.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  
  .loader__spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #001F3F;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .hero__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  
  